import * as React from "react";
import {css, Theme} from "@emotion/react";
import {carsTheme} from "@rw/themes";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Alert, AlertType} from "../../app/components/alert/Alert";
import {useAlert} from "../../app/components/alert/useAlert";
import {apiLink} from "../../app/routes/api_link";
import {postRequest} from "../../app/utils/request_response_utils/request";

import contactBG from "../assets/contact_bg.jpg";

interface AddCarFormValuesType {
    name: string;
    email: string;
    message: string;
}

const validationSchema = Yup.object({
    name: Yup.string().required("Imię jest wymagane"),
    email: Yup.string().email("Niepoprawny email").required("Email jest wymagany"),
    message: Yup.string().required("Wiadomość jest wymagana")
});

export const ContactPageDynamic = () => {
    const {alert, showAlert} = useAlert();

    const handleSubmit = async (values: AddCarFormValuesType, {resetForm}: {resetForm: () => void}) => {
        const data = {
            firstName: values.name,
            message: values.message,
            email: values.email
        };

        try {
            const url = apiLink.contactUsForm({})({...data});

            return postRequest({}, url, {...data})
                .then((res: any) => {
                    showAlert(`Dziękujem za kontakt. Ktoś niebawem się z tobą skontaktuje.`, AlertType.success, 5000);
                    resetForm();
                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                });
        } catch (err) {
            //  console.error("Błąd podczas wysyłania formularza:", err);
            showAlert(`Błąd podczas wysyłania formularza`, AlertType.danger, 5000);
        }
    };

    return (
        <div css={containerStyles}>
            <Formik onSubmit={handleSubmit} initialValues={{name: "", email: "", message: ""}} validationSchema={validationSchema}>
                {({isSubmitting, errors, touched, values, setFieldValue}) => {
                    return (
                        <Form css={formStyles}>
                            <div>
                                <h1 css={h1}>Kontakt</h1>
                                <p css={pStyle}>
                                    Zakupauto specjalizuje się w sprzedaży nowych i używanych samochodów. Jeśli masz pytania, potrzebujesz wsparcia lub chcesz
                                    dowiedzieć się więcej o naszej ofercie samochodów na sprzedaż, skontaktuj się z nami – jesteśmy tu, aby Ci pomóc!
                                </p>
                            </div>
                            <div>
                                <Field name="name" placeholder="Imię" css={inputStyles} />
                                <ErrorMessage name="name" component="div" css={{color: "red", fontSize: "0.875rem"}} />
                            </div>

                            <div>
                                <Field name="email" type="email" placeholder="Email" css={inputStyles} />
                                <ErrorMessage name="email" component="div" css={{color: "red", fontSize: "0.875rem"}} />
                            </div>

                            <div>
                                <Field name="message" as="textarea" placeholder="Wiadomość" css={[inputStyles, {height: "100px", resize: "none"}]} />
                                <ErrorMessage name="message" component="div" css={{color: "red", fontSize: "0.875rem"}} />
                            </div>

                            <button type="submit" css={btnSubmit}>
                                Wyślij
                            </button>

                            {alert.message && <Alert type={alert.type} message={alert.message} />}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const pStyle = css`
    color: #fff;
    text-align: center;
`;

const btnSubmit = (theme: Theme) => css`
    border: 2px solid #fff;
    height: 42px;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 35px;
    position: relative;
    width: fit-content;
    margin: 20px auto;

    background: ${carsTheme.colors.brand_primary};

    font-weight: 600;
    color: #ffffff;

    border: 2px solid ${carsTheme.colors.brand_primary};
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        color: ${carsTheme.colors.gray};
        background: ${carsTheme.colors.brand_primary};
    }
`;

const h1 = css`
    font-weight: bold;
    font-size: 4rem;
    color: #ffffff;
    text-align: center;
`;

const containerStyles = (theme: Theme) => css`
    width: 100vw;
    height: auto;
    background: url(${contactBG}) no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4rem 0;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        padding: 0;
        height: 100vh;
    }
`;

const formStyles = (theme: Theme) => css`
    background: ${theme.colors.gray};
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 4rem 3rem;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        max-width: 950px;
        padding: 6rem 10rem;
    }
`;

const inputStyles = css`
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;

    &:focus {
        border-color: ${carsTheme.colors.brand_primary};
        outline: none;
        box-shadow: 0 0 5px rgba(106, 17, 203, 0.5);
    }
`;
