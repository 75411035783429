import {connect, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import styled from "@emotion/styled";
import {css} from "@linaria/core";
import {Breadcrumbs} from "@rw/breadcrumbs";

import {appLink} from "../../../../../../packages/utils/cars_routes";
import {IStore} from "../../../app/reducers/hybrid_reducer";
import {CarMakeTypes} from "../../../cars_data/cars_data";
import {Container} from "../../../homepage/components/CarBrandsSection";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {getOfferListUrl} from "../url_utils/get_offer_list_url";
import CarList from "./CarList";
import {SeoLocationLinks} from "./SeoLocationLinks";

export const capitalizeFirstLetter = (str: string) => {
    if (!str) return str; // Handle empty string or null
    return str.charAt(0).toUpperCase() + str.slice(1);
};

interface IStateProps {
    carsData: {
        //  meta: IOfferListApiResponseMeta;
        cars: ICarListOfferResponse[];

        //requestState: RequestState;
    };
    carMakeType: CarMakeTypes;
}

export interface ICarsListDynamicProps extends IStateProps, RouteComponentProps<{}> {
    test?: boolean;
}
export interface IBreadcrumb {
    label: string;
    link: string;
    asStatic?: boolean;
}

export const generateCarListBreadcrumbs = (carMakeType: CarMakeTypes) => {
    const listingBreadcrumbs: IBreadcrumb[] = [
        {
            label: "Osobowe",
            link: appLink.searchResult.universal({offerType: "osobowe"}),
            asStatic: false
        }
    ];

    if (carMakeType) {
        const carTypeBreadcrumb: IBreadcrumb = {
            label: `${carMakeType}`,
            link: getOfferListUrl({
                offerType: "osobowe",
                type: carMakeType
            })
        };
        return [...listingBreadcrumbs, carTypeBreadcrumb];
    }

    return listingBreadcrumbs;
};
export const CarListDynamicC = (props: ICarsListDynamicProps) => {
    const breadcrumbsData = generateCarListBreadcrumbs(props.carMakeType);
    const location = useSelector((store: IStore) => store.carList.location.location);
    const locationPLName = useSelector((store: IStore) => store.carList.locationPLName);

    const getTitleCarMakeType = () => {
        return props.carMakeType === CarMakeTypes.MERCEDESBENZ ? "mercedes-Benz" : props.carMakeType;
    };

    const renderH1Title = () => {
        if (location?.location) {
            if (location?.subLocation) {
                if (props.carMakeType) {
                    return `${capitalizeFirstLetter(locationPLName.cityName)} ${capitalizeFirstLetter(props.carMakeType)} - samochody osobowe`;
                }
                return `${capitalizeFirstLetter(locationPLName.cityName)} - samochody osobowe`;
            }
            if (props.carMakeType) {
                return `${capitalizeFirstLetter(location.location)} ${capitalizeFirstLetter(getTitleCarMakeType())} - samochody osobowe`;
            }
            return `${capitalizeFirstLetter(location.location)} - samochody osobowe`;
        }
        if (props.carMakeType) {
            return `${capitalizeFirstLetter(getTitleCarMakeType())} - samochody osobowe`;
        }
        return `Samochody osobowe`;
    };

    const H1Title = !props.carMakeType
        ? `${location?.location ? `${capitalizeFirstLetter(location.location)} ` : ""}Samochody osobowe`
        : `${capitalizeFirstLetter(props.carMakeType === CarMakeTypes.MERCEDESBENZ ? "mercedes-Benz" : props.carMakeType)} - samochody osobowe`;
    return (
        <div className={listingHolder}>
            <div className={pageHolder}>
                <Container>
                    <CarListBreadcrumbs breadcrumbs={breadcrumbsData} />
                    <h1>{renderH1Title()}</h1>
                    <CarList cars={props.carsData.cars} carMakeType={props.carMakeType} />
                    <SeoLocationLinks />
                </Container>
            </div>
        </div>
    );
};

function mapStateToProps(state: IStore): IStateProps {
    return {
        carsData: state.carList.cars,
        carMakeType: state.carList.latestQuery.type
    };
}

export const CarListDynamic = connect(mapStateToProps, null)(CarListDynamicC);

const listingHolder = css`
    position: relative;

    padding: 0 2rem;

    @media (min-width: 1230px) {
        padding: 0;
    }
`;

const pageHolder = css`
    position: relative;
    width: 100%;
`;
const CarListBreadcrumbs = styled(Breadcrumbs)`
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: wrap;
`;
