import {LoginPageDynamic} from "../src/admin/login/LoginPageDynamic";
import {NewPasswordAccountDynamic} from "../src/admin/set_new_pass/NewPasswordAccountDynamic";
import {DashboardDynamic} from "../src/dashboard/Dashboard";
import {EditDynamic} from "../src/dashboard/edit_car/EditDynamic";
import {FavouriteCarsDynamic} from "../src/favourites/components/FavouriteCarsDynamic";
import {HomepageDynamic} from "../src/homepage/HomepageDynamic";
import {NewsDynamic} from "../src/news/components/NewsDynamic";
import {ContactPageDynamic} from "../src/pages/contact/ContactPageDynamic";
import {prepareHydratedApp} from "./utils/prepare_hydrate_app";

const config = [
    {reactDynamic: "homepage", view: <HomepageDynamic />},
    {reactDynamic: "contactpage", view: <ContactPageDynamic />},
    {reactDynamic: "favourite-cars", view: <FavouriteCarsDynamic />},
    {reactDynamic: "news-cars", view: <NewsDynamic />},
    {reactDynamic: "login-page", view: <LoginPageDynamic />},
    {reactDynamic: "reset-password-page", view: <NewPasswordAccountDynamic />},
    {reactDynamic: "dashboard-page", view: <DashboardDynamic />},
    {reactDynamic: "dashboard-edit-page", view: <EditDynamic />}
];

(() => prepareHydratedApp(config))();
