export const engineTypes = [
    {value: "benzyna", label: "Benzyna"},
    {value: "benzyna_cng", label: "Benzyna + CNG"},
    {value: "benzyna_lpg", label: "Benzyna + LPG"},
    {value: "diesel", label: "Diesel"},
    {value: "elektryczny", label: "Elektryczny"},
    {value: "hybryda", label: "Hybryda"},
    {value: "hybryda_plugin", label: "Hybryda Plug-in"}
];

export const carBodyTypes = [
    {value: "auta_male", label: "Auta małe"},
    {value: "miejskie", label: "Miejskie"},
    {value: "Coupe", label: "Coupe"},
    {value: "Kabriolet", label: "Kabriolet"},
    {value: "Kombi", label: "Kombi"},
    {value: "Kompakt", label: "Kompakt"},
    {value: "Minivan", label: "Minivan"},
    {value: "Sedan", label: "Sedan"},
    {value: "Suv", label: "Suv"}
];

type OptionType = {value: string; label: string};

export const getLabelByValue = (value: string, options: OptionType[]): string | undefined => {
    const foundOption = options.find((option) => option.value === value);
    return foundOption ? foundOption.label : undefined;
};
