import React from "react";
import {css} from "@emotion/react";

import {appLink} from "../../../../../packages/utils/cars_routes";

interface CarBrandProps {
    slug: string;
    name: string;
    image: string;
}

export const CarBrand: React.FC<CarBrandProps> = ({name, image, slug}) => {
    return (
        <a
            css={carBrandCard(image)}
            href={appLink.searchResult.universal({
                offerType: "osobowe",
                type: slug
            })}
        >
            <div css={overlay}></div>
            <h3 css={brandName}>{name}</h3>
        </a>
    );
};

const carBrandCard = (image: string) => css`
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    margin: 8px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    width: 100%;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    transition: all 0.3s ease;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }

    &:hover h3 {
        text-decoration: none;
        font-weight: bold;
        transform: scale(1.2);
    }
`;

const overlay = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 8px;
`;

const brandName = css`
    position: relative;
    z-index: 2;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    transition: all 0.4s ease-in-out;
`;
