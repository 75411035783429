import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {Button} from "@rw/button";
import {carsTheme} from "@rw/themes";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import {Alert, AlertType} from "../../app/components/alert/Alert";
import {useAlert} from "../../app/components/alert/useAlert";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {apiLink} from "../../app/routes/api_link";
import {Voivodeship, voivodeshipNames} from "../../app/types/locationTypes";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {carBodyTypes, engineTypes} from "../add_car/options/select_options";
import {AdditionalFeaturesPartForm} from "../components/Additionalfeatures";
import {AppearancePartForm} from "../components/Appearance";
import {ComfortAndConveniencePartForm} from "../components/ComfortAndConvenience";
import {MultimediaPartForm} from "../components/Multimedia";
import {SaftyPartForm} from "../components/SaftyOpt";

interface IGalleryObjType {
    id: string;
    file: File;
    preview: string;
}

interface AddCarFormValues {
    title: string;
    content: string;
    //  expiry: number;
    make: string;
    model: string;
    year: string;
    millage: string;
    engine: string;
    engine_size: string;
    power: number | null;
    price: string;
    gearbox: string;
    color: string;
    voivodeship: string | null;
    city: string | null;
    //gallery: IGalleryObjType[];
    // mainImage?: IGalleryObjType;
    registry_date: string;
    seller_name: string;
    phone_number: string;
    version: string;
    generation: string;
    doors_counts: number;
    seats_counts: number;
    vin_id: string;
    body_type: string;
    damaged: boolean;
    imported: boolean;
    multimedia: string[];
    comfort_and_convenience: string[];
    appearance_and_materials: string[];
    safety: string[];
    additional_features: string[];
}
//TODO change expiry to lower when more data available

const editCarValidationSchema = Yup.object({
    title: Yup.string().required("Tytuł jest wymagany")
});

interface IAddCarProps {
    user: {
        id: number | null;
    };
    nonce: string | null;
}

const uploadFilesToApi = async (files: File[], jwtToken: string, nonce: string | null): Promise<any[]> => {
    const uploadedFiles: File[] = [];
    for (const file of files) {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios
            .post("https://rw-web-api.eu/wp-json/wp/v2/media", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${jwtToken}`
                }
            })
            .then((res: any) => {
                console.log("response media", res);
                uploadedFiles.push(res);
                return res;
            })
            .catch((error) => {
                console.error("Błąd odpowiedzi:", error.response.data);
            });
    }
    return uploadedFiles;
};

const MAX_IMAGES = 10;

export const EditCar = (props: IAddCarProps) => {
    const {alert, showAlert} = useAlert();
    const fetchedCarData = useSelector((store: IStore) => store.editOfferData.carEditData);
    const handleSubmit = async (values: AddCarFormValues, {resetForm}: {resetForm: () => void}) => {
        try {
            // const files = values.gallery.map((img) => img.file);
            // const jwtToken = localStorage.getItem("token") ?? "";
            //
            // const uploadedImages = await uploadFilesToApi(files, jwtToken, props.nonce);
            // if (!uploadedImages.length) {
            //     throw new Error("Nie udało się przesłać żadnych plików.");
            // }
            // const galleryData = uploadedImages.map((image: {data: {id: number}}) => image.data.id);

            const data = {
                author_id: props.user.id || 1,
                title: values.title,
                content: values.content,
                // expiry: values.expiry,
                make: values.make,
                model: values.model,
                year: values.year,
                millage: parseInt(values.millage.replace(" ", "")),
                engine: values.engine,
                engine_size: parseInt(values.engine_size.replace(" ", "")),
                power: values.power,
                price: values.price,
                gearbox: values.gearbox,
                color: values.color,
                voivodeship: values.voivodeship,
                city: values.city,
                //  gallery: galleryData,
                // mainImage: galleryData[0]
                registry_date: values.registry_date,
                seller_name: values.seller_name,
                phone_number: values.phone_number,
                version: values.version,
                generation: values.generation,
                doors_counts: values.doors_counts,
                seats_counts: values.seats_counts,
                vin_id: values.vin_id,
                body_type: values.body_type,
                damaged: values.damaged,
                imported: values.imported,
                multimedia: values.multimedia,
                additional_features: values.additional_features,
                appearance_and_materials: values.appearance_and_materials,
                comfort_and_convenience: values.comfort_and_convenience,
                safety: values.safety
            };

            const url = apiLink.editCar({})({...data, offer_id: fetchedCarData.id});

            return postRequest({}, url, {...data, offer_id: fetchedCarData.id})
                .then((res: any) => {
                    // console.log("response", res);
                    showAlert(`Ogłoszenie zaktualizowano.`, AlertType.success, 5000);
                    resetForm();
                    return res;
                })
                .catch((err) => {
                    console.warn("err on reset", err);
                });
        } catch (err) {
            console.error("Błąd podczas aktualizacji ogłoszenia:", err);
            showAlert(`Błąd podczas aktualizacji ogłoszenia`, AlertType.danger, 5000);
        }
    };

    return (
        <>
            <Formik<AddCarFormValues> initialValues={fetchedCarData} validationSchema={editCarValidationSchema} onSubmit={handleSubmit}>
                {({isSubmitting, errors, touched, values, setFieldValue}) => {
                    // const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
                    //     if (event.target.files) {
                    //         const files = Array.from(event.target.files);
                    //         if (values.gallery.length + files.length > MAX_IMAGES) {
                    //             showAlert(`Nie możesz dodać więcej niż ${MAX_IMAGES} zdjęć.`, AlertType.danger, 5000);
                    //             return;
                    //         }
                    //
                    //         const filePreviews = files.map((file) => ({
                    //             file,
                    //             preview: URL.createObjectURL(file)
                    //         }));
                    //
                    //         try {
                    //             if (!values.mainImage && files.length > 0) {
                    //                 const firstImage = {
                    //                     id: files[0].name,
                    //                     file: files[0],
                    //                     preview: filePreviews[0].preview
                    //                 };
                    //                 setFieldValue("mainImage", firstImage);
                    //             }
                    //
                    //             setFieldValue("gallery", [
                    //                 ...values.gallery,
                    //                 ...filePreviews.map((preview, index) => ({
                    //                     id: files[index].name, // Możesz użyć unikalnego ID, jeśli jest dostępne
                    //                     file: files[index],
                    //                     preview: preview.preview
                    //                 }))
                    //             ]);
                    //         } catch (error) {
                    //             console.error("Error uploading files:", error);
                    //         }
                    //     }
                    // };

                    // const handleRemoveImage = (imageId: string) => {
                    //     setFieldValue(
                    //         "gallery",
                    //         values.gallery.filter((image) => image.id !== imageId)
                    //     );
                    // };

                    // const handleSetMainImage = (imageFile: IGalleryObjType) => {
                    //     // Przestawienie zdjęcia na początek galerii, jeśli nie jest już głównym
                    //     if (values.mainImage !== imageFile) {
                    //         const updatedGallery = [imageFile, ...values.gallery.filter((image) => image !== imageFile)];
                    //         setFieldValue("gallery", updatedGallery);
                    //     }
                    //
                    //     // Ustawienie wybranego zdjęcia jako główne
                    //     setFieldValue("mainImage", imageFile);
                    // };

                    return (
                        <FormWrapper>
                            <h2 css={{margin: "2rem 0"}}>Edytuj Ogłoszenie </h2>
                            <StyledForm>
                                <Field css={inputStyle} name="title" type="text" placeholder="Tytuł" required />
                                {errors.title && touched.title && errors.title}
                                <Field css={inputStyle} name="content" as="textarea" rows={6} placeholder="Opis" />
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="make" type="text" placeholder="Marka" required />
                                    <Field css={inputStyle} name="model" type="text" placeholder="Model" required />
                                    <Field as="select" css={inputStyle} name="body_type" placeholder="Typ nadwozia" required>
                                        <option value="" disabled>
                                            Typ nadwozia
                                        </option>
                                        {carBodyTypes.map((bodyType) => (
                                            <option key={bodyType.value} value={bodyType.value}>
                                                {bodyType.label}
                                            </option>
                                        ))}
                                    </Field>
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="year" type="text" placeholder="Rocznik" required />
                                    <Field css={inputStyle} name="millage" type="text" placeholder="Stan Licznika" required />
                                    <Field
                                        css={inputStyle}
                                        name="power"
                                        type="number"
                                        placeholder="Moc (KM)"
                                        value={values.power === 0 ? "" : values.power}
                                        required
                                    />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field as="select" css={inputStyle} name="engine" placeholder="Silnik" required>
                                        <option value="" disabled>
                                            Wybierz silnik
                                        </option>
                                        {engineTypes.map((engine) => (
                                            <option key={engine.value} value={engine.value}>
                                                {engine.label}
                                            </option>
                                        ))}
                                    </Field>

                                    <Field css={inputStyle} name="engine_size" type="text" placeholder="Pojemność silnika" required />
                                    <Field as="select" css={inputStyle} name="gearbox" placeholder="Skrzynia biegów" required>
                                        <option value="" disabled>
                                            Skrzynia biegów
                                        </option>
                                        <option value="manualna">Manualna</option>
                                        <option value="automatyczna">Automatyczna</option>
                                    </Field>
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field as="select" disabled css={inputStyle} name="voivodeship" placeholder="Województwo">
                                        <option value="" disabled>
                                            Wybierz województwo
                                        </option>
                                        {Object.entries(Voivodeship).map(([key, value]) => (
                                            <option key={key} value={key.toLowerCase()}>
                                                {voivodeshipNames[value]}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="voivodeship" component="div" style={{color: "red"}} />
                                    <Field css={inputStyle} name="city" disabled type="text" placeholder="Miasto" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="price" type="number" placeholder="Cena" required />
                                    <Field css={inputStyle} name="color" type="text" placeholder="Kolor" required />
                                </FieldWrapperGrid>

                                {/* Pole do przesyłania plików */}
                                {/*<input type="file" accept="image/*" multiple onChange={handleFileChange} />*/}
                                {/*{values.gallery.length >= MAX_IMAGES && <p style={{color: "red"}}>Osiągnąłeś limit {MAX_IMAGES} zdjęć.</p>}*/}
                                {/*/!* Podgląd zdjęć *!/*/}
                                {/*<div style={{display: "flex"}}>*/}
                                {/*    {values.gallery.map((image) => (*/}
                                {/*        <div key={image.preview}>*/}
                                {/*            <div css={previewWrapper}>*/}
                                {/*                <img*/}
                                {/*                    src={image.preview}*/}
                                {/*                    alt="Preview"*/}
                                {/*                    css={(theme) => previewImage(theme, values.mainImage === image)}*/}
                                {/*                    onClick={() => handleSetMainImage(image)}*/}
                                {/*                />*/}
                                {/*                {values.mainImage === image ? <p css={mainImageStyle}>Zdjęcie głowne</p> : null}*/}
                                {/*            </div>*/}
                                {/*            <button*/}
                                {/*                type="button"*/}
                                {/*                onClick={() => handleRemoveImage(image.id)}*/}
                                {/*                style={{display: "block", marginTop: "5px", backgroundColor: "red", color: "white"}}*/}
                                {/*            >*/}
                                {/*                Usuń*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="registry_date" type="date" placeholder="Data pierwszej rejestracji" />
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="version" type="text" placeholder="Wersja" />
                                    <Field css={inputStyle} name="generation" type="text" placeholder="Generacja" />
                                </FieldWrapperGrid>

                                <FieldWrapperGrid>
                                    <Field
                                        css={inputStyle}
                                        name="doors_counts"
                                        type="number"
                                        value={values.doors_counts === 0 ? "" : values.doors_counts}
                                        placeholder="Liczba drzwi"
                                    />
                                    <Field
                                        css={inputStyle}
                                        name="seats_counts"
                                        type="number"
                                        value={values.seats_counts === 0 ? "" : values.seats_counts}
                                        placeholder="Liczba siedzeń"
                                    />
                                </FieldWrapperGrid>

                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="vin_id" type="text" placeholder="VIN" />
                                </FieldWrapperGrid>

                                <FieldWrapperGrid>
                                    <Field css={inputStyle} name="seller_name" type="text" placeholder="Imię sprzedającego" />
                                    <Field css={inputStyle} name="phone_number" type="tel" placeholder="Numer kontaktowy" />
                                    {errors.phone_number && touched.phone_number && errors.phone_number}
                                </FieldWrapperGrid>
                                <FieldWrapperGrid>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Importowany <Field type="checkbox" name="imported" css={{marginLeft: "8px"}} />
                                    </label>
                                    <label css={{display: "flex", alignItems: "center", gap: "8px"}}>
                                        Uszkodzony <Field type="checkbox" name="damaged" css={{marginLeft: "8px"}} />
                                    </label>
                                </FieldWrapperGrid>
                                <MultimediaPartForm name="multimedia" values={values} />
                                <ComfortAndConveniencePartForm name="comfort_and_convenience" values={values} />
                                <AppearancePartForm name="appearance_and_materials" values={values} />
                                <SaftyPartForm name="safety" values={values} />
                                <AdditionalFeaturesPartForm name="additional_features" values={values} />

                                <div>
                                    <Button type="submit" size="lg" variant="primary" disabled={isSubmitting}>
                                        Edytuj ogłoszenie
                                    </Button>
                                </div>
                            </StyledForm>
                        </FormWrapper>
                    );
                }}
            </Formik>
            {alert.message && <Alert type={alert.type} message={alert.message} />}
        </>
    );
};
//TODO this style used in many forms add Component

const previewWrapper = css`
    display: inline-block;
    margin: 10px;
    &:nth-child(1) {
        margin-left: 0;
    }
`;

const previewImage = (theme: Theme, mainImage: boolean) => css`
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: ${mainImage ? `2px solid ${theme.colors.brand_primary}` : "2px solid lightgray"};
`;
const mainImageStyle = (theme: Theme) => css`
    color: ${theme.colors.brand_primary};
    margin: 0;
    font-weight: bold;
`;

const inputStyle = css`
    border: 1px solid #a7a7ab;
    padding-block: 10px;
    border-radius: 8px;
    padding-left: 0.6rem;
    background: #ffffff;
    &:hover,
    &:focus,
    &:active,
    &:focus:active {
        border-color: ${carsTheme.colors.brand_primary};
        outline: none;
    }

    &::placeholder {
        padding-left: 0.6rem;
    }
`;

const FormWrapper = styled.div`
    width: 100%;
    //max-width: 400px;
    padding: 40px 0;

    background: #fff;
    z-index: 10;
    position: relative;
`;

const FieldWrapperGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-size: 14px;
        font-weight: bold;
    }
`;
