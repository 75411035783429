import {prefixPath} from "@rw/prefix_path";

import {CarMakes} from "./car_makes";

export const AGENCY_AND_DEVELOPERS_LOCATION_PATHS = [
    "bialystok",
    "bydgoszcz",
    "elblag",
    "gdansk",
    "gdynia",
    "gliwice",
    "katowice",
    "kielce",
    "kolobrzeg",
    "krakow",
    "legionowo",
    "lublin",
    "lodz",
    "olsztyn",
    "poznan",
    "rzeszow",
    "sopot",
    "swinoujscie",
    "szczecin",
    "torun",
    "warszawa",
    "wroclaw"
];

const LOCATION_STRING = AGENCY_AND_DEVELOPERS_LOCATION_PATHS.join("|");
const CAR_MAKES_STRING = CarMakes.join("|");

export const appPath = prefixPath("/", {
    mainPage: "",
    aboutUs: "o-nas/",
    news: "news/",
    contact: "kontakt/",
    favourites: "ulubione/",
    article: prefixPath("blog/", {
        base: "",
        detailOrCategoryList: ":slug([\\w-]+)/" // path handles both the article detail and list
    }),
    fullOffer: prefixPath("oferta/", {
        base: "",
        detail: prefixPath(":offerSlug([\\w-]+)-:carId([\\w-]+)/", {
            base: ""
        })
    }),
    offer: prefixPath("oferta/", {
        base: "",
        detail: prefixPath(":offerSlug([\\w-]+)-:carId([\\w-]+)/", {
            base: ""
        })
    }),
    page: prefixPath("strony/", {
        base: "",
        detail: ":slug([\\w-]+)/"
    }),
    search: "search/",
    searchResult: prefixPath(":offerType(osobowe|ciezarowe)/", {
        base: "",
        universal: `:type(${CAR_MAKES_STRING})?/:location?/:subLocation?/`,
        //universal: ":type(audi|opel)?/:location?/:subLocation?/:subSubLocation?/",
        universalTag: `:type(${CAR_MAKES_STRING})?/:location?/:subLocation?/tag/:tag/`
        //universalTag: ":type(audi|opel)?/:location?/:subLocation?/:subSubLocation?/t/:tag/"
    }),
    admin: prefixPath("admin/", {
        login: "zaloguj/",
        register: "zarejestruj/",
        newPassword: "nowe-haslo/",
        userDashboard: prefixPath("panel/", {
            base: "",
            edit: "edytuj/:offerId([\\d]+)/"
        })
    })
});
