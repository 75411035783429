import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";
import {appLink} from "@rw/cars_routes";

import {Alert, AlertType} from "../../app/components/alert/Alert";
import {useAlert} from "../../app/components/alert/useAlert";
import {apiLink} from "../../app/routes/api_link";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {postRequest} from "../../app/utils/request_response_utils/request";
import {ICarListOfferResponse} from "../../meta_data/reducers/meta_data_reducer";
import {setDashboardUserOffersTypes} from "../Dashboard";

interface ICarsListTable {
    cars: ICarListOfferResponse[];
}

type reqState = null | boolean;

export const CarListTable = (props: ICarsListTable) => {
    const {cars} = props;
    const dispatch = useDispatch();
    const {alert, showAlert} = useAlert();
    const [deleteRequest, setDeleteRequest] = useState<{deleteState: reqState; deleteMessage: string | null}>({deleteState: null, deleteMessage: null});

    useEffect(() => {
        if (deleteRequest.deleteMessage) {
            showAlert(deleteRequest.deleteMessage, deleteRequest.deleteState ? AlertType.success : AlertType.danger, 5000);
        }
    }, [deleteRequest, showAlert]);

    const handleDelete = (id: number) => {
        const updatedCars = cars.filter((car) => car.id !== id);
        const token = localStorage.getItem("token") ?? "";
        showAlert("Usuwanie oferty...", AlertType.info, 3000);

        const url = apiLink.deleteCar({})({offer_id: id});

        return postRequest({}, url, {offer_id: id}, "deleteOffer", {jwtToken: token})
            .then((res: any) => {
                setDeleteRequest({deleteState: true, deleteMessage: `Oferat ${id} usunięta`});
                showAlert(`Oferta ${id} usunięta`, AlertType.success, 5000);
                dispatch({type: setDashboardUserOffersTypes.success, result: updatedCars});
                // return res;
            })
            .catch((err) => {
                console.warn("err on reset", err.responseError);
                if (err.responseError?.code === "jwt_auth_invalid_token") {
                    setDeleteRequest({deleteState: false, deleteMessage: "Brak autoryzacji"});
                    showAlert("Brak autoryzacji", AlertType.danger, 5000);
                }
            });
    };

    return (
        <>
            <table css={tableStyle} className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Marka</th>
                        <th scope="col">Model</th>
                        <th scope="col">Rocznik</th>
                        <th scope="col">Akcje</th>
                    </tr>
                </thead>
                <tbody>
                    {cars.map((car) => (
                        <tr key={car.id}>
                            <th scope="row">{car.id}</th>
                            <td>{car.acf.make}</td>
                            <td>{car.acf.model}</td>
                            <td>{car.acf.year}</td>
                            <td>
                                <div css={btnActionWrap}>
                                    <button css={[btn, deleteBtn]} onClick={() => handleDelete(car.id)}>
                                        Usuń
                                    </button>
                                    <button
                                        css={[btn, editBtn]}
                                        onClick={() => {
                                            window.location.href = appLink.admin.editOffer({offerId: car.id});
                                        }}
                                    >
                                        Edytuj
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {alert.message && <Alert type={alert.type} message={alert.message} />}{" "}
        </>
    );
};

const btnActionWrap = css`
    display: flex;
`;

const btn = (theme: Theme) => css`
    background: gray;
    color: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 4px 8px;
    border: 2px solid gray;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        background: #fff;
        color: gray;
    }
`;
const deleteBtn = (theme: Theme) => css`
    background: ${theme.colors.brand_danger};
    color: #ffffff;
    margin-right: 1rem;
    border: 2px solid ${theme.colors.brand_danger};

    &:hover {
        cursor: pointer;
        background: #fff;
        color: ${theme.colors.brand_danger};
    }
`;
const editBtn = (theme: Theme) => css`
    background: gray;
    color: #ffffff;
`;

const tableStyle = css`
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    display: table;
    text-indent: initial;
    unicode-bidi: isolate;
    border-spacing: 2px;
    border-color: gray;

    th,
    td {
        padding: 1.25rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        text-align: left;
    }
    thead tr {
        background-color: #fff;
    }

    tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;
