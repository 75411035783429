export enum Voivodeship {
    Dolnoslaskie = "dolnoslaskie",
    KujawskoPomorskie = "kujawsko-pomorskie",
    Lubelskie = "lubelskie",
    Lubuskie = "lubuskie",
    Lodzkie = "lodzkie",
    Malopolskie = "malopolskie",
    Mazowieckie = "mazowieckie",
    Opolskie = "opolskie",
    Podkarpackie = "podkarpackie",
    Podlaskie = "podlaskie",
    Pomorskie = "pomorskie",
    Slaskie = "slaskie",
    Swietokrzyskie = "swietokrzyskie",
    WarminskoMazurskie = "warminsko-mazurskie",
    Wielkopolskie = "wielkopolskie",
    Zachodniopomorskie = "zachodniopomorskie"
}
export const voivodeshipNames: Record<Voivodeship, string> = {
    [Voivodeship.Dolnoslaskie]: "Dolnośląskie",
    [Voivodeship.KujawskoPomorskie]: "Kujawsko-Pomorskie",
    [Voivodeship.Lubelskie]: "Lubelskie",
    [Voivodeship.Lubuskie]: "Lubuskie",
    [Voivodeship.Lodzkie]: "Łódzkie",
    [Voivodeship.Malopolskie]: "Małopolskie",
    [Voivodeship.Mazowieckie]: "Mazowieckie",
    [Voivodeship.Opolskie]: "Opolskie",
    [Voivodeship.Podkarpackie]: "Podkarpackie",
    [Voivodeship.Podlaskie]: "Podlaskie",
    [Voivodeship.Pomorskie]: "Pomorskie",
    [Voivodeship.Slaskie]: "Śląskie",
    [Voivodeship.Swietokrzyskie]: "Świętokrzyskie",
    [Voivodeship.WarminskoMazurskie]: "Warmińsko-Mazurskie",
    [Voivodeship.Wielkopolskie]: "Wielkopolskie",
    [Voivodeship.Zachodniopomorskie]: "Zachodniopomorskie"
};

interface State {
    name: string;
    state_code: string;
    slug: string;
    plname: string;
}

interface CountryData {
    name: string;
    iso3: string;
    iso2: string;
    states: State[];
}

const polandData: CountryData = {
    name: "Poland",
    iso3: "POL",
    iso2: "PL",
    states: [
        {
            name: "Greater Poland Voivodeship",
            state_code: "WP",
            slug: Voivodeship.Wielkopolskie,
            plname: voivodeshipNames[Voivodeship.Wielkopolskie]
        },
        {
            name: "Kuyavian-Pomeranian Voivodeship",
            state_code: "KP",
            slug: Voivodeship.KujawskoPomorskie,
            plname: voivodeshipNames[Voivodeship.KujawskoPomorskie]
        },
        {
            name: "Lesser Poland Voivodeship",
            state_code: "MA",
            slug: Voivodeship.Malopolskie,
            plname: voivodeshipNames[Voivodeship.Malopolskie]
        },
        {
            name: "Lower Silesian Voivodeship",
            state_code: "DS",
            slug: Voivodeship.Dolnoslaskie,
            plname: voivodeshipNames[Voivodeship.Dolnoslaskie]
        },
        {
            name: "Lublin Voivodeship",
            state_code: "LU",
            slug: Voivodeship.Lubelskie,
            plname: voivodeshipNames[Voivodeship.Lubelskie]
        },
        {
            name: "Lubusz Voivodeship",
            state_code: "LB",
            slug: Voivodeship.Lubuskie,
            plname: voivodeshipNames[Voivodeship.Lubuskie]
        },
        {
            name: "Łódź Voivodeship",
            state_code: "LD",
            slug: Voivodeship.Lodzkie,
            plname: voivodeshipNames[Voivodeship.Lodzkie]
        },
        {
            name: "Masovian Voivodeship",
            state_code: "MZ",
            slug: Voivodeship.Mazowieckie,
            plname: voivodeshipNames[Voivodeship.Mazowieckie]
        },
        {
            name: "Opole Voivodeship",
            state_code: "OP",
            slug: Voivodeship.Opolskie,
            plname: voivodeshipNames[Voivodeship.Opolskie]
        },
        {
            name: "Podkarpackie Voivodeship",
            state_code: "PK",
            slug: Voivodeship.Podkarpackie,
            plname: voivodeshipNames[Voivodeship.Podkarpackie]
        },
        {
            name: "Podlaskie Voivodeship",
            state_code: "PD",
            slug: Voivodeship.Podlaskie,
            plname: voivodeshipNames[Voivodeship.Podlaskie]
        },
        {
            name: "Pomeranian Voivodeship",
            state_code: "PM",
            slug: Voivodeship.Pomorskie,
            plname: voivodeshipNames[Voivodeship.Pomorskie]
        },
        {
            name: "Silesian Voivodeship",
            state_code: "SL",
            slug: Voivodeship.Slaskie,
            plname: voivodeshipNames[Voivodeship.Slaskie]
        },
        {
            name: "Świętokrzyskie Voivodeship",
            state_code: "SK",
            slug: Voivodeship.Swietokrzyskie,
            plname: voivodeshipNames[Voivodeship.Swietokrzyskie]
        },
        {
            name: "Warmian-Masurian Voivodeship",
            state_code: "WN",
            slug: Voivodeship.WarminskoMazurskie,
            plname: voivodeshipNames[Voivodeship.WarminskoMazurskie]
        },
        {
            name: "West Pomeranian Voivodeship",
            state_code: "ZP",
            slug: Voivodeship.Zachodniopomorskie,
            plname: voivodeshipNames[Voivodeship.Zachodniopomorskie]
        }
    ]
};

export const getVoivodeshipName = (voivodeship: Voivodeship): string => {
    return voivodeshipNames[voivodeship];
};

export const getStateNameBySlug = (slug: string): string | null => {
    const state = polandData.states.find((state) => state.slug === slug);
    return state ? state.name : null;
};
