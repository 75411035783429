import {css, Theme} from "@emotion/react";
import {appLink} from "@rw/cars_routes";
import {carsTheme} from "@rw/themes";

import {addOffer} from "../../navigation/NavigationMain";

import heroImg from "../../styles/assets/background/christmas-offer.jpg";

export const ChristmasOffer = () => {
    return (
        <section css={heroWrapper}>
            <div css={offerContent}>
                <h2 css={offerHeading}>
                    Dodaj swoją ofertę <span css={primaryColor}>za darmo</span> do końca roku – nie przegap okazji!
                </h2>
                <a css={[addOffer, offerBordered]} href={appLink.admin.login}>
                    Dodaj ofertę
                </a>
            </div>
        </section>
    );
};

const primaryColor = css`
    color: ${carsTheme.colors.brand_primary};
`;

const offerBordered = (theme: Theme) => css`
    border: 2px solid #fff;
    height: 42px;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
    width: fit-content;
    margin: 20px auto;

    background: transparent;
    font-weight: 600;

    font-size: 16px;
    color: ${carsTheme.colors.brand_primary};

    border: 2px solid ${carsTheme.colors.brand_primary};
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        color: #ffffff;
        background: ${carsTheme.colors.brand_primary};
    }
`;

const offerContent = (theme: Theme) => css`
    display: flex;
    margin: auto;
    flex-direction: column;
`;

const offerHeading = (theme: Theme) => css`
    color: #ffffff;
    max-width: 320px;
    text-align: center;
    margin: auto;
`;

const heroWrapper = (theme: Theme) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
    padding: 5rem 1rem 2rem;
    z-index: 1;
    background-image: url("${heroImg}");
    background-repeat: no-repeat;
    background-position: center -4rem;
    background-size: cover;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 3rem 2rem 2rem;
        height: 400px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 1rem;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 38%, rgba(0, 0, 0, 0.21) 70%, rgba(0, 0, 0, 0.47) 100%);

        @media screen and (min-width: ${theme.breakpoints.screen_md}) {
            display: none;
        }
    }
`;
