export const fetchCitiesByStateName = async (stateName: string): Promise<string[]> => {
    try {
        const response = await fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                country: "Poland",
                state: stateName
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Request failed");
        }

        const data = await response.json();
        if (!data.data || !Array.isArray(data.data)) {
            throw new Error("Invalid response format");
        }

        return data.data; // Zakładam, że lista miast znajduje się w `data.data`
    } catch (error) {
        console.error("Error fetching cities:", error);
        return []; // Zwróć pustą tablicę w przypadku błędu
    }
};
