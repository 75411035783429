import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink, parseOfferSlugToObject} from "@rw/cars_routes";

interface CarProps {
    brand: string;
    model: string;
    year: number;
    mileage: number;
    price: number;
    image: string;
    slug: string;
}

const CarBoxContainer = styled.div`
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const CarImage = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    display: block;
    transition: all 0.3s ease-in-out;
`;
const carImageWrapper = css`
    overflow: hidden;
    max-height: 160px;
    display: flex;
    align-items: center;
`;

const CarDetails = styled.div`
    color: #555;

    h3 {
        font-size: 1.2em;
        margin-bottom: 8px;
    }

    p {
        margin: 4px 0;
        font-size: 0.9em;
    }
`;
const boxLink = css`
    &:hover {
        text-decoration: none !important;
        img {
            transform: scale(1.1);
        }
    }
`;

export const CarBox: React.FC<CarProps> = ({brand, model, year, mileage, price, image, slug}) => {
    const offerUrlParams = parseOfferSlugToObject(slug);
    const offerHref = appLink.fullOffer.detail.base(offerUrlParams);
    const brandLabel = brand === "mercedesbenz" ? "Mercedes-Benz" : brand;
    return (
        <CarBoxContainer>
            <a css={boxLink} href={offerHref}>
                <div css={carImageWrapper}>
                    <CarImage src={image} alt={`${brand} ${model}`} />
                </div>
                <CarDetails>
                    <h3>
                        {brandLabel} {model}
                    </h3>
                    <p>Rok: {year}</p>
                    <p>Przebieg: {mileage} km</p>
                    <p>Cena: {price} PLN</p>
                </CarDetails>
            </a>
        </CarBoxContainer>
    );
};
