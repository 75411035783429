export type Option = {
    label: string;
    value: string;
};

export const getOptionsByCategory = (categoryValue: string): Option[] | undefined => {
    const category = categoriesPL.find((cat) => cat.value === categoryValue);

    if (!category) {
        return undefined;
    }

    switch (categoryValue) {
        case "multimedia":
            return multimediaOptions;
        case "comfort_and_convenience":
            return comfortOptions;
        case "appearance_and_materials":
            return appearanceOptions;
        case "safety":
            return safetyOptions;
        case "additional_features":
            return additionalFeaturesOptions;
        default:
            return [];
    }
};

export enum CategoriesENEnum {
    ComfortAndConvenience = "comfort_and_convenience",
    Multimedia = "multimedia",
    AppearanceAndMaterials = "appearance_and_materials",
    Safety = "safety",
    AdditionalFeatures = "additional_features"
}

export const categoriesPL: Option[] = [
    {label: "Komfort i Wygoda", value: "comfort_and_convenience"},
    {label: "Multimedia", value: "multimedia"},
    {label: "Wygląd i Materiały", value: "appearance_and_materials"},
    {label: "Bezpieczeństwo", value: "safety"},
    {label: "Dodatkowe Funkcje", value: "additional_features"}
];

export const categoriesEN: Option[] = [
    {label: "Comfort and Convenience", value: "comfort_and_convenience"},
    {label: "Multimedia", value: "multimedia"},
    {label: "Appearance and Materials", value: "appearance_and_materials"},
    {label: "Safety", value: "safety"},
    {label: "Additional Features", value: "additional_features"}
];

export const multimediaOptions: Option[] = [
    {label: "Bluetooth", value: "bluetooth"},
    {label: "Radio", value: "radio"},
    {label: "Zestaw głośnomówiący", value: "zestaw_glosnomowiacy"},
    {label: "Gniazdo USB", value: "gniazdo_usb"},
    {label: "System nawigacji satelitarnej", value: "system_nawigacji"},
    {label: "Apple CarPlay / Android Auto", value: "apple_carplay_android_auto"},
    {label: "Odtwarzacz CD/DVD", value: "odtwarzacz_cd"},
    {label: "Ekran dotykowy", value: "ekran_dotykowy"},
    {label: "System dźwięku przestrzennego", value: "dzwiek_przestrzenny"},
    {label: "Sterowanie radiem z kierownicy", value: "sterowanie_radiem"},
    {label: "Wejście AUX", value: "wejscie_aux"},
    {label: "Wi-Fi w samochodzie", value: "wifi_samochod"},
    {label: "Ładowarka indukcyjna", value: "ladowarka_indukcyjna"},
    {label: "Tuner DAB+", value: "tuner_dab"},
    {label: "System kamer 360°", value: "system_kamer_360"},
    {label: "Obsługa głosowa", value: "obsluga_glosowa"},
    {label: "Dysk twardy do przechowywania muzyki", value: "dysk_muzyka"},
    {label: "Podłączenie HDMI", value: "podlaczenie_hdmi"},
    {label: "System rozrywki dla pasażerów z tyłu", value: "rozrywka_tyl"},
    {label: "Integracja ze smartfonem", value: "integracja_smartfon"},
    {label: "Odtwarzacz multimedialny", value: "odtwarzacz_multimedialny"},
    {label: "Subwoofer", value: "subwoofer"},
    {label: "Wyjścia słuchawkowe dla pasażerów", value: "wyjscia_sluchawkowe"},
    {label: "Obsługa Spotify/Tidal/Deezer", value: "spotify_tidal_deezer"},
    {label: "Streaming audio przez Bluetooth", value: "streaming_bluetooth"}
];

// Komfort i Wygoda
// Technologia i Multimedia
// Wygląd i Materiały
// Bezpieczeństwo
// Dodatkowe Funkcje

export const comfortOptions: Option[] = [
    {label: "Klimatyzacja manualna", value: "air_conditioning_manual"},
    {label: "Klimatyzacja automatyczna dwustrefowa", value: "air_conditioning_dual_zone"},
    {label: "Klimatyzacja automatyczna czterostrefowa", value: "air_conditioning_quad_zone"},
    {label: "Podgrzewane fotele - przód", value: "heated_seats_front"},
    {label: "Podgrzewane fotele - tył", value: "heated_seats_rear"},
    {label: "Wentylowane fotele", value: "ventilated_seats"},
    {label: "Podłokietniki - przód", value: "armrest_front"},
    {label: "Podłokietniki - tył", value: "armrest_rear"},
    {label: "Regulacja foteli manualna", value: "seat_adjustment_manual"},
    {label: "Regulacja foteli elektryczna", value: "seat_adjustment_electric"},
    {label: "Regulacja foteli z pamięcią ustawień", value: "seat_adjustment_memory"},
    {label: "Przyciemniane szyby", value: "tinted_windows"},
    {label: "Roleta przeciwsłoneczna tylnej szyby manualna", value: "sunblind_rear_manual"},
    {label: "Roleta przeciwsłoneczna tylnej szyby elektryczna", value: "sunblind_rear_electric"},
    {label: "Automatyczne otwieranie bagażnika", value: "automatic_trunk_opening"},
    {label: "Kierownica ogrzewana", value: "heated_staring_wheal"},
    {label: "Czujnik deszczu", value: "rain_sensor"},
    {label: "Elektryczne szyby przednie", value: "automatic_window_front"},
    {label: "Elektryczne szyby tylnie", value: "automatic_window_back"}
];

export const appearanceOptions: Option[] = [
    {label: "Tapicerka materiałowa", value: "upholstery_fabric"},
    {label: "Tapicerka skórzana", value: "upholstery_leather"},
    {label: "Tapicerka półskórzana", value: "upholstery_half_leather"},
    {label: "Kolor tapicerki jasny", value: "upholstery_color_light"},
    {label: "Kolor tapicerki ciemny", value: "upholstery_color_dark"},
    {label: "Podsufitka jasna", value: "headliner_light"},
    {label: "Podsufitka ciemna", value: "headliner_dark"},
    {label: "Obręcze stalowe", value: "steel_wheels"},
    {label: "Relingi dachowe", value: "roof_rails"}
];

export const safetyOptions: Option[] = [
    {label: "Poduszki powietrzne", value: "airbags_6"},
    {label: "Poduszka powietrzna kierowcy", value: "driver_airbag"},
    {label: "Poduszka powietrzna pasażera", value: "passenger_airbag"},
    {label: "Boczna poduszka powietrzna kierowcy", value: "driver_side_airbag"},
    {label: "Boczne poduszki powietrzne - przód", value: "front_side_airbags"},
    {label: "Isofix (punkty mocowania fotelika dziecięcego)", value: "isofix"},
    {label: "System ABS", value: "abs"},
    {label: "System ESP", value: "esp"},
    {label: "System ASR", value: "asr"},
    {label: "Asystent pasa ruchu", value: "lane_assist"},
    {label: "Tempomat klasyczny", value: "cruise_control"},
    {label: "Tempomat adaptacyjny", value: "adaptive_cruise_control"},
    {label: "Hamulec postojowy ręczny", value: "handbrake_manual"},
    {label: "Hamulec postojowy elektryczny", value: "handbrake_electric"},
    {label: "Światła LED", value: "led_lights"},
    {label: "Światła Xenon", value: "xenon_lights"},
    {label: "Światła Matrix", value: "matrix_lights"}
];

export const additionalFeaturesOptions: Option[] = [
    {label: "Hak holowniczy stały", value: "tow_hitch_fixed"},
    {label: "Hak holowniczy składany", value: "tow_hitch_folding"},
    {label: "Bagażnik dachowy", value: "roof_box"},
    {label: "Box dachowy", value: "roof_box_case"},
    {label: "Zestaw naprawczy", value: "repair_kit"},
    {label: "Koło zapasowe", value: "spare_wheel"},
    {label: "Ogrzewanie postojowe", value: "parking_heater"},
    {label: "Zbiornik na AdBlue", value: "adblue_tank"},
    {label: "Wyświetlacz Head-Up", value: "head_up_display"}
];
